import React from "react"
import { graphql } from "gatsby"

const TagTemplate = ({ data, pageContext }) => {
  const articles = data.pageTags.nodes

  return (
    <>
      <h1>{pageContext.tag}</h1>
      {articles.map(article => {
        return (
          <div>
            <h1>{article.frontmatter.title}</h1>
          </div>
        )
      })}
    </>
  )
}

export const query = graphql`
  query ($tag: String) {
    pageTags: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      nodes {
        frontmatter {
          tags
          title
          date(formatString: "MMMM DD, YYYY")
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default TagTemplate
